<template>
  <div class="fomr">
    <el-form
      :model="dynamicValidateForm"
      ref="dynamicValidateForm"
      label-width="100px"
      class="demo-dynamic"
    >
      <el-form-item
        prop="uesrName"
        label="姓名"
        :rules="[
      { required: true, message: '请输入姓名', trigger: 'blur' },
    
    ]"
      >
        <el-input v-model="dynamicValidateForm.uesrName"></el-input>
      </el-form-item>
      <el-form-item
        label="联系电话"
        prop="phone"
        :rules="[
                {required: true, message: '请输入联系电话', trigger: 'blur'},
                
    ]"
      >
        <el-input v-model="dynamicValidateForm.phone"></el-input>
      </el-form-item>
      <el-form-item
        label="邮箱"
        prop="email"
        :rules="[
                {required: true, message: '请输入邮箱', trigger: 'blur'},
                { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }
                
    ]"
      >
        <el-input v-model="dynamicValidateForm.email"></el-input>
      </el-form-item>
      <el-form-item label="说明">
        <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="dynamicValidateForm.explains"></el-input>
      </el-form-item>

      <el-form-item style=" text-align: center;">
        <el-button
          type="info"
          @click="submitForm('dynamicValidateForm')"
          style="background-color: #999999;"
          size="medium"
        >提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { homeService } from "@/api"

export default {
  data() {
    return {
      dynamicValidateForm: {
        uesrName: "",
        email: "",
        phone: "",
        explains: ""
      }
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let data = {
                liaisonEmail: this.dynamicValidateForm.email,
                liaisonMan: this.dynamicValidateForm.uesrName,
                liaisonPhone: this.dynamicValidateForm.phone,
                explains: this.dynamicValidateForm.explains
          }
          homeService.addCandidates(data).then(res => {
            this.$message({
              message: "提交成功",
              type: "success",
              duration: 1 * 1000
            })
          })
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>