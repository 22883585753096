<template>
    <div class="Box">
        <div class="leftBox">
            <textTitle text="我要应聘"/>
            <div style="width: 500px; margin: 30px 100px;">
                <inviteFrame/>
            </div>
        </div>
        <sidebar/>
    </div>
</template>
<script>
import textTitle from "@/components/headTail/textTitle";
import sidebar from "@/components/sidebar/sidebar";
import inviteFrame from "./frame"
export default {

    components:{
        textTitle,
        sidebar,
        inviteFrame,
    }
}
</script>